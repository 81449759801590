@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/mixins.scss";

.ProgressBar {
    width: 100%;
    position: relative;
    height: 0.5rem;
    pointer-events: none;
    margin-bottom: 0.3rem;

    &--visible {
        .ProgressBar__progress--interval {
            transform: scale(1, 1);
        }
        .ProgressBar__dot--interval {
            opacity: 1;
        }
    }

    &--hidden {
        .ProgressBar__progress--interval {
            transform: scale(0, 1);
        }
        .ProgressBar__dot--interval {
            opacity: 0;
        }
    }

    &:has(.ProgressBar__dot--tail) {
        .ProgressBar__progress {
            transform: scale(0, 1);
        }
    }

    &__background,
    &__progress {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 0 4px 4px 0;
        transform-origin: left;
    }

    &__background {
        width: calc(100% - 2px);
    }

    &__progress {
        position: relative;
        transform-origin: right;
        width: 0%;
        transition: 500ms transform;

        &:hover {
            .ProgressBar__tooltip {
                visibility: visible;
            }
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .ProgressBar__progress;
                background-color: $color;
            }
        }
        &--gradient {
            @each $name, $color in $colors {
                &.ProgressBar__progress--#{$name} {
                    @extend .ProgressBar__progress;
                    background: linear-gradient(270deg, $color 50%, transparent 100%);
                    @each $nameTo, $colorTo in $colors {
                        &--#{$nameTo} {
                            @extend .ProgressBar__progress;
                            background: linear-gradient(270deg, $color 0%, $colorTo 100%);
                        }
                    }
                }
            }
        }

        &--interval {
            @extend .ProgressBar__progress;
            transform-origin: left;
            background-color: rgba($color__gray-2, 0.4);
            transform-origin: center;
            transition: 500ms transform;
        }
    }

    .ProgressBar__tooltip {
        visibility: hidden;
    }

    &__dot {
        pointer-events: all;

        &:hover {
            z-index: 10;

            .ProgressBar__tooltip {
                visibility: visible;
            }
        }

        &--tail:after {
            content: "";
            display: inline-block;
            position: absolute;
            left: calc(50% - 1px);
            height: 1rem;
            width: 2px;
            background-color: $color__gray-1;
            border-radius: 4px;
            top: 100%;
        }

        &--interval {
            height: 140% !important;
            transition: 1s opacity;
        }

        @each $name, $color in $colors {
            &--#{$name} {
                @include dot($color);
                background-color: $color !important;
            }

            &.ProgressBar__progress--#{$name} {
                &ProgressBar__dot {
                    @include dot($color);
                }
            }
        }
    }

    &__background {
        background-color: rgba($color__gray-2, 0.4);

        @each $name, $color in $colors {
            &--#{$name} {
                @extend .ProgressBar__background;
                background-color: $color;
            }
        }
    }

    &__number {
        position: absolute;
        left: calc(100% + 0.5rem);
        top: 50%;
        transform: translateY(-50%);
        font-weight: $fw-bold;

        &--left {
            @extend .ProgressBar__number;
            left: unset;
            right: 0.5rem;
            color: $color__black;
        }
    }

    &__tooltip {
        position: absolute;
        bottom: calc(100% + 0.5rem);

        &--title {
            font-size: $fs-s;
        }

        &--text {
            display: flex;
            justify-content: space-between;
            gap: 2rem;

            span {
                white-space: nowrap;
            }

            @each $name, $color in $colors {
                &#{$name} {
                    @extend .ProgressBar__tooltip--text;

                    & span {
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;

                        &:before {
                            content: "";
                            @include dot($color);
                            height: 0.6rem;
                            position: relative;
                            transform: unset;
                            right: unset;
                            top: unset;
                            border-color: $color;
                        }
                    }
                }
            }
        }
    }

    &__font {
        @each $name, $fontsize in $fontsizes {
            &--#{$name} {
                font-size: $fontsize;
            }
        }
    }
}
