@import "./colors.scss";
@import "./fonts.scss";

@mixin dot($color) {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120%;
    aspect-ratio: 1 / 1;
    border: 2px solid $color__white;
    border-radius: 100%;
    background-color: $color;
    top: 50%;
    transform: translate(-50%, -50%);
    right: -12px;
    box-sizing: content-box;
    font-weight: $fw-bold;
    color: $color__white;
}