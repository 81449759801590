@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.BarList {
    position: relative;
    margin-bottom: 3rem;
    height: 100%;

    &__title {
        margin: 1rem 0 0.5rem 0.5rem;
        font-size: $fs-s;
    }

    &__list {
        position: relative;
    }

    &__target {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &--line {
            position: absolute;
            top: -0.5rem;
            width: 3px;
            height: calc(100% + 1.5rem);
            background-color: $color__gray-2;
            border-radius: 2px;
        }

        &--number {
            color: $color__gray-2;
            font-size: $fs-s;
            font-weight: $fw-bold;
            position: absolute;
            top: calc(100% + 0.8rem);
            transform: translateX(-50%);
            background-color: $color__gray-1;
            height: 35px;
            width: 35px;
            border: 2px solid $color__gray-2;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__sublist {
        margin-top: 1rem;
        position: relative;
    }

    &__bars {
        display: flex;
        flex-direction: column;
    }
}
