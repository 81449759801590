@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Tabs {
    display: flex;
    border-bottom: 1px solid $color__gray-2;
    gap: 2rem;
    padding: 0 1rem;

    &__tab {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: -1.5px;
        padding: 1rem;
        color: $color__black;
        font-size: $fs-l;
        font-weight: $fw-bold;
        color: $color__gray-2;

        svg {
            fill: $color__gray-2;
        }

        &:hover {
            cursor: pointer;
        }

        &--active {
            font-weight: $fw-bold;
            border-bottom: 3px solid $color__green;
            color: $color__black;

            svg {
                fill: $color__black;
            }
        }
    }
}
