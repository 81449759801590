@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Tag {
    cursor: default;
    display: inline-block;
    position: relative;
    background-color: $color__gray-3;
    padding: 0.3rem 2rem 0.3rem 0.7rem;
    border-radius: 20px;
    margin-right: 0.3rem;
    font-size: $fs-s;

    &:last-child {
        margin-right: 0;
    }

    &__cross {
        cursor: pointer;
        position: absolute;
        height: 24px;
        width: 24px;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: inline-block;
            border-radius: 4px;
            left: 50%;
            width: 2px;
            height: 12px;
            top: 50%;
            background-color: $color__black;
            transform: translateY(-50%) rotate(45deg);
            transform-origin: center center;
        }

        &:after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}
