@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.Table {
    width: 100%;

    table {
        width: 100%;
        text-align: left;
        border-collapse: collapse;
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 20;
    }

    th,
    th span {
        cursor: pointer;
    }

    th {
        padding: 0.5rem 0.3rem;
        border-bottom: 2px solid $color__white;
        font-weight: $fw-medium;
        font-size: $fs-s;
    }

    th,
    td {
        border-bottom: 1px solid $color__gray-2;
    }

    td {
        padding: 0.7rem 0.3rem;
    }

    @each $name, $color in $colors {
        &--#{$name} {
            @extend .Table;
            background-color: $color;
            color: $color__black;

            thead {
                background-color: $color__white;
            }

            tr:hover:not(thead tr) {
                background-color: darken($color, 5);
            }

            .Table__link {
                a {
                    color: $color__black;

                    &:hover {
                        color: $color__gray-2;
                    }
                }
            }

            .Table__sortby:before {
                border-bottom-color: darken($color, 20);
            }

            .Table__sortby:after {
                border-top-color: darken($color, 20);
            }

            .Table__sortby--desc:after {
                border-top-color: darken($color, 50);
            }

            .Table__sortby--asc:before {
                border-bottom-color: darken($color, 50);
            }
        }
    }

    @each $name, $fontsize in $fontsizes {
        &--#{$name} {
            font-size: $fontsize;
        }
    }

    &__number {
        text-align: right;
    }

    &__header {
        &:not(.Table__header--nosorting) {
            .Table__headerInner {
                cursor: pointer;
                display: flex;
                padding: 0.3rem 0;
                gap: 0.5rem;
            }
        }

        &--nosorting {
            text-align: center;
        }
    }

    &__sortby {
        position: relative;
        width: 1rem;

        &:before,
        &:after {
            content: "";
            position: absolute;
            border: 5px solid transparent;
            display: inline-block;
            height: 0;
            width: 0;
            right: 0;
            top: 50%;
        }

        &:before {
            transform: translateY(calc(-50% - 7px));
            border-bottom-color: $color__gray-2;
        }

        &:after {
            transform: translateY(calc(-50% + 7px));
            border-top-color: $color__gray-2;
        }

        &--desc,
        &--asc {
            @extend .Table__sortby;
        }

        &--desc:after {
            border-top-color: $color__white;
        }

        &--asc:before {
            border-bottom-color: $color__white;
        }
    }

    &__number {
        text-align: right;
    }

    &__boolean {
        text-align: center;

        &--checkmark {
            color: $color__green;
        }

        &--cross {
            color: $color__red;
        }
    }

    &__tags {
        span {
            padding: 0.3rem 0.5rem;
            background-color: $color__gray-2;
            border-radius: 2rem;

            &:not(:last-child) {
                margin-right: 0.5rem;
            }
        }
    }

    &__dropdown {
        position: relative;
        z-index: 0;

        &--active {
            position: relative;
            z-index: 10;
        }
    }

    &__checkbox {
        height: 100%;
    }
}

@media (max-width: 770px) {
    .Table {
        overflow-x: auto;

        td {
            font-size: $fs-m !important;
            &__headerInner {
                span {
                    font-size: 0;
                }
                &:first-letter {
                    font-size: 15px;
                }
            }
        }
    }
}
